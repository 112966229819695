import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FILE_SERVICE } from "config/common";

import { Button } from "components/Button";
import { Form } from "components/Form";
import { ModalAction } from "components/Modal";

import FileService from "lib/api/File.service";
import {
  convertErrorToSchema,
  countryToCountryCode,
  Errors,
  slugify,
} from "lib/helpers";
import {
  useAddClientMutation,
  useUpdateClientMutation,
} from "lib/slices/presentationServiceAPISlice";

type Props = {
  clientId?: string;
  defaultData?: Client;
};

export function FormSANewClient({ clientId, defaultData }: Props) {
  const { t } = useTranslation();
  const [addClient, { isLoading }] = useAddClientMutation();
  const [showModal, setShowModal] = useState({
    visibilityFor: {
      created: false,
      edited: false,
    },
    modalContent: "",
  });
  const [updateClient] = useUpdateClientMutation();
  const [errors, setErrors] = useState<Errors>();
  const navigate = useNavigate();

  const uploadAssets = async (
    logo: File[],
    banner: File[],
    client: { name: string; id: string },
    isCreated: boolean,
  ) => {
    const clientSlug = slugify(client.name);

    if (logo?.length > 0) {
      if (logo[0].size !== 0) {
        await FileService.upload(logo[0], clientSlug, "logo").then(() => {
          updateClient({
            id: client.id,
            body: {
              logo_uri: `${FILE_SERVICE}/${clientSlug}-logo.png`,
            },
          }).unwrap();
        });
      }
    } else if (defaultData?.logo_uri?.length) {
      await FileService.delete(clientSlug, "logo").then(() => {
        updateClient({
          id: client.id,
          body: {
            logo_uri: null,
          },
        }).unwrap();
      });
    }

    if (banner?.length > 0) {
      if (banner[0].size !== 0) {
        await FileService.upload(banner[0], clientSlug, "banner").then(() => {
          updateClient({
            id: client.id,
            body: {
              banner_uri: `${FILE_SERVICE}/${clientSlug}-banner.png`,
            },
          }).unwrap();
        });
      }
    } else if (defaultData?.banner_uri?.length) {
      await FileService.delete(clientSlug, "banner").then(() => {
        updateClient({
          id: client.id,
          body: {
            banner_uri: null,
          },
        }).unwrap();
      });
    }

    handleToggleModal(true, isCreated ? "created" : "edited", client.name);
  };

  const handleToggleModal = (
    show: boolean,
    value?: "created" | "edited",
    modalContent = "",
  ) => {
    if (show) {
      setShowModal({
        visibilityFor: {
          created: value === "created",
          edited: value === "edited",
        },
        modalContent,
      });
    }

    if (show === false) {
      navigate("/sa/clients");
    }
  };

  const onSubmit = async (data: any) => {
    data.formData.countryCode = countryToCountryCode(data.formData.country);
    data.formData.country = undefined;

    const logo = data.formData.logo_uri;
    const banner = data.formData.banner_uri;

    // Keep the uploaded files in the UI if some error occur on backend
    const shallowCloneData = {
      ...data.formData,
    };
    delete shallowCloneData.logo_uri;
    delete shallowCloneData.banner_uri;
    delete shallowCloneData.country;
    
    if (clientId && defaultData) {
      await updateClient({
        id: clientId,
        body: {
          ...shallowCloneData,
        },
      })
        .unwrap()
        .then(() => uploadAssets(logo, banner, defaultData, false))
        .catch((error) => {
          if ("data" in error.data) {
            const t = convertErrorToSchema(error.data.data);
            setErrors(t);
          } else {
            const t = convertErrorToSchema(error.data);
            setErrors(t);
          }
        });
    } else {
      await addClient({
        ...shallowCloneData,
      })
        .unwrap()
        .then((client) => uploadAssets(logo, banner, client, true))
        .catch((error) => {
          if ("data" in error.data) {
            const t = convertErrorToSchema(error.data.data);
            setErrors(t);
          } else {
            const t = convertErrorToSchema(error.data);
            setErrors(t);
          }
        });
    }
  };

  const formData = {
    onSubmit,
    uiSchema: {
      "ui:order": [
        "name",
        "zipCode",
        "legalEntity",
        "visibleContactAddress",
        "orgNr",
        "visibleContactPhone",
        "address1",
        "website",
        "city",
        "vatNo",
        "country",
        "spacer",
        "banner_uri",
        "logo_uri",
      ],
      "ui:submitButtonOptions": {
        intent: "primary",
        icon: { name: "floppydisk", intent: "white" },
        disabled: isLoading,
      },
      name: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": t("pages.superAdmin.clients.tableColumns.clientName"),
      },
      visibleContactAddress: {
        "ui:widget": "email",
        "ui:width": "50%",
        "ui:placeholder": "example@email.com",
      },
      legalEntity: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": t(
          "pages.superAdmin.clients.tableColumns.legalEntity",
        ),
      },
      visibleContactPhone: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": "+359",
      },
      orgNr: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": "xxxx-xxxx",
      },
      website: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": "https://example.com",
      },
      city: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": t("pages.superAdmin.clients.tableColumns.city"),
      },
      address1: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": t("pages.superAdmin.clients.tableColumns.address"),
      },
      vatNo: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": "xxxxxxxxxxx",
      },
      country: {
        "ui:widget": "select",
        "ui:placeholder": t("pages.account.form.country"),
        "ui:width": "50%",
      },
      zipCode: {
        "ui:widget": "text",
        "ui:width": "50%",
        "ui:placeholder": "xxxx",
      },
      spacer: {
        "ui:widget": () => <div></div>,
        "ui:options": {
          label: false,
        },
      },
      logo_uri: {
        "ui:widget": "FileWidget",
        "ui:width": "50%",
        "ui:options": {
          accept: "image/*",
        },
      },
      banner_uri: {
        "ui:widget": "FileWidget",
        "ui:width": "50%",
        "ui:options": {
          accept: "image/*",
        },
      },
    },
    schema: {
      type: "object",
      required: [
        "name",
        "legalEntity",
        "orgNr",
        "visibleContactAddress",
        "visibleContactPhone",
        "website",
        "country",
        "zipCode",
        "city",
        "address1",
        "vatNo",
      ],
      properties: {
        name: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.clientName"),
          maxLength: 100,
          default: defaultData?.name,
          readOnly: defaultData?.name ? true : false,
        },
        visibleContactAddress: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.email"),
          maxLength: 50,
          default: defaultData?.visibleContactAddress,
          format: "email",
        },
        legalEntity: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.legalEntity"),
          maxLength: 100,
          default: defaultData?.legalEntity,
        },
        visibleContactPhone: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.phone"),
          maxLength: 18,
          default: defaultData?.visibleContactPhone,
          format: "phoneWithCountryCode",
        },
        orgNr: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.orgNr"),
          maxLength: 20,
          default: defaultData?.orgNr,
        },
        website: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.website"),
          format: "website",
          maxLength: 50,
          default: defaultData?.website,
        },
        city: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.city"),
          maxLength: 50,
          default: defaultData?.city,
        },
        address1: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.address"),
          maxLength: 50,
          default: defaultData?.address1,
        },
        vatNo: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.vat"),
          maxLength: 15,
          default: defaultData?.vatNo,
        },
        country: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.country"),
          enum: t("countries", { returnObjects: true }),
          default: countryToCountryCode(undefined, defaultData?.countryCode),
        },
        zipCode: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.zip"),
          minLength: 4,
          maxLength: 6,
          default: defaultData?.zipCode,
        },
        spacer: { type: "string", title: " " },
        logo_uri: {
          title: t("pages.superAdmin.clients.tableColumns.logo"),
          type: "array",
          items: {
            type: "object",
            format: "data-url",
          },
          default: defaultData?.logo_uri
            ? [new File([], defaultData.logo_uri)]
            : undefined,
        },
        banner_uri: {
          title: t("pages.superAdmin.clients.tableColumns.banner"),
          type: "array",
          items: {
            type: "object",
            format: "data-url",
          },
          default: defaultData?.banner_uri
            ? [new File([], defaultData.banner_uri)]
            : undefined,
        },
      },
    },
  };

  return (
    <>
      <Form id="new-sa-client-form" {...formData} asyncErrors={errors}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            intent="primary-light"
            text={t("cancel")}
            onClick={() => navigate("/sa/clients")}
            style={{ width: "50%", margin: "0 10px" }}
          />

          <Button
            intent="black"
            icon={{ name: "add", intent: "white", size: 12 }}
            type="submit"
            text={
              clientId
                ? t("pages.superAdmin.clients.editClient")
                : t("pages.superAdmin.clients.newClient")
            }
            style={{ width: "50%", margin: "0 10px" }}
          />
        </div>
      </Form>

      {(showModal.visibilityFor.created || showModal.visibilityFor.edited) && (
        <ModalAction
          direction="column"
          title={t("pages.superAdmin.clients.success")}
          content={() => {
            return showModal.visibilityFor.created ? (
              <p>
                {t("pages.superAdmin.clients.createdSuccessfully", {
                  name: showModal.modalContent,
                })}
              </p>
            ) : (
              <p>{t("pages.superAdmin.clients.editedSuccessfully")}</p>
            );
          }}
          isOpen={
            showModal.visibilityFor.created || showModal.visibilityFor.edited
              ? true
              : false
          }
          intent="success"
          ctaBtns={[
            {
              text: t("pages.superAdmin.clients.confirm"),
              intent: "primary-light",
              fill: true,
              onClick: () => handleToggleModal(false),
            },
          ]}
          onClose={() => handleToggleModal(false)}
        />
      )}
    </>
  );
}
